import Cookies from "universal-cookie"

export function getCookieValue(key) {
  const cookies = new Cookies()
  return cookies.get(key)
}

export function createCookie(key, value) {
  const cookies = new Cookies()
  if (value) {
    cookies.set(key, value, { path: "/" })
  }
}
