import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Button = styled.button`
  position: relative;
  min-width: 110px;
  width: ${({ width }) => (width ? `${width}px` : "auto")};
  max-width: 325px;
  margin: auto 0 0 auto;
  display: inline-flex;
  text-align: center;
  border-radius: 6px;
  font-size: 1.25rem;
  line-height: ${({ small }) => (small ? "28px" : "40px")};
  font-weight: 600;
  border: none;
  padding: 10px;
  div {
    margin: 0 auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  span {
    font-size: 1rem;
    font-weight: 400;
    display: block;
  }
  &:hover {
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
  &:disabled,
  &:disabled:hover {
    color: #fff;
    background: ${({ theme }) => theme.colors.veryLightBlue};
    border-color: ${({ theme }) => theme.colors.veryLightBlue};
  }
  .lazyload-placeholder {
    display: inline-block;
  }
`

const Primary = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.primary};
  border: 2px solid ${({ theme }) => theme.colors.primary};
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    background: transparent;
  }
`

export function PrimaryButton(props) {
  const { children, ...rest } = props
  return (
    <Primary {...rest}>
      <div>{children}</div>
    </Primary>
  )
}

const Secondary = styled(Button)`
  color: #fff;
  background: linear-gradient(to right, ${({ theme }) => theme.colors.clearBlue}, #1c67e5);
  vertical-align: top;
  &:hover {
    background: ${({ theme }) => theme.colors.clearBlue};
  }
  &:before {
    content: "";
    background: #000;
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    bottom: -10px;
    opacity: 0.4;
    filter: blur(10px);
    z-index: -1;
  }
`

export function SecondaryButton(props) {
  const { children, ...rest } = props
  return (
    <Secondary {...rest}>
      <div>{children}</div>
    </Secondary>
  )
}

const Simple = styled(Button)`
  color: ${({ theme }) => theme.colors.dark};
  background: transparent;
  border: 2px solid transparent;
  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }
  div {
    margin: 0;
  }
  svg {
    color: ${({ theme }) => theme.colors.primary};
  }
`

export function SimpleButton(props) {
  const { children, ...rest } = props
  return (
    <Simple {...rest}>
      <div>{children}</div>
    </Simple>
  )
}

const Phone = styled.a`
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary};
  border: 1px solid;
  border-color: ${({ dark, theme }) => (dark ? theme.colors.dark : theme.colors.primary)};
  border-radius: 6px;
  font-size: 24px;
  line-height: 32px;
  padding: 0.5rem 1rem;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
  }
  @media (min-width: 400px) {
    font-size: 32px;
  }
`

export function CallUsButton(props) {
  return (
    <Phone href="tel:+351308802519" {...props}>
      <FontAwesomeIcon icon={["fam", "phone"]} size="xs" className="mr-3" />
      308 802 519
    </Phone>
  )
}

const SmallPhone = styled(Phone)`
  border-width: 2px;
`

export function SmallCallUsButton(props) {
  return (
    <SmallPhone href="tel:+351308802519" {...props}>
      <FontAwesomeIcon icon={["fam", "phone"]} size="xs" />
    </SmallPhone>
  )
}
