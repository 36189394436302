import Link from "next/link"
import styled from "styled-components"
import { Container } from "reactstrap"
import { CallUsButton, SmallCallUsButton } from "../styled/Buttons"
import { sendEvent } from "../../scripts/GoogleAnalytics"

const Wrapper = styled(Container)`
  padding-top: 1rem;
  padding-bottom: 1rem;
`

const NavBar = styled.nav`
  padding: 2rem 0;
  background: transparent;
  position: relative;
  z-index: 999;
`

const Logo = styled.img`
  height: 40px;
  width: auto;
  @media (min-width: 1200px) {
    height: 82px;
  }
`

const Phone = styled.div`
  float: right;
  margin-top: 0.5rem;
`

export default function TopBar(props) {
  const { dark, pageName } = props

  const onClickCTA = () => {
    sendEvent("incoming call", { event_category: pageName, event_label: "incoming call header" })
  }

  return (
    <NavBar>
      <Wrapper>
        <Link href="/">
          <a>
            <Logo
              sizes="275px, (min-width: 1999px) 134px"
              srcSet={`${process.env.NEXT_PUBLIC_CLOUDINARY}w_275,q_auto,f_auto/micochederenting/${
                dark ? "logo-cdr-black" : "logo-cdr-white"
              } 275w, ${process.env.NEXT_PUBLIC_CLOUDINARY}w_134,q_auto,f_auto/micochederenting/${
                dark ? "logo-cdr-black" : "logo-cdr-white"
              } 134w`}
              alt="Carrosderenting Logo"
            />
          </a>
        </Link>
        <Phone className="d-none d-xl-block">
          <CallUsButton dark={dark} onClick={onClickCTA} />
        </Phone>
        {dark && (
          <Phone className="d-block d-lg-none">
            <SmallCallUsButton dark={dark} onClick={onClickCTA} />
          </Phone>
        )}
      </Wrapper>
    </NavBar>
  )
}

TopBar.defaultProps = {
  dark: false
}
