import { GA_TRACKING_ID } from "./GoogleAnalytics"

export function init() {
  setTimeout(() => {
    gtag("js", new Date())

    gtag("config", GA_TRACKING_ID, { optimize_id: "GTM-NKZM8DW" })
    gtag("config", "AW-660345721")
  }, 3000)
}
