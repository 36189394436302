export const MARKETING_FIELDS = [
  "gclid",
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_adgroup",
  "utm_content",
  "utm_term",
  "adposition",
  "device",
  "loc_physical_ms",
  "matchtype",
  "adid"
]

export const TESTIMONIALS = [
  {
    id: 1,
    name: "Miguel",
    content:
      "A entrega do carro foi de acordo com o esperado, inclusívamente duas semanas antes. Estou contente com a Swipcar, já que me deu a oportunidade de disfrutar do meu carro. Seguramente, voltarei a renovar o meu renting com eles.",
    image: "testimonials/miki",
    car: "Peugeot 3008 BlueHDI Active",
    rating: 5
  },
  {
    id: 2,
    name: "Mildred",
    content:
      "Dentro da prestação mensal está tudo incluído, manutenção, seguro, ... Tudo! O que acaba por ser muito cómodo para mim, já que deixo de preocupar com as gestões do carro e aproveito para o disfrutar.",
    image: "testimonials/mildred",
    car: "Fiat 500 1.2 Loungue",
    rating: 5
  },
  {
    id: 3,
    name: "Manuel",
    content:
      "Há alguns anos que estava a considerar utilizar carros a gasoleo, dadas as restrições que começam a surgir nas grandes cidades, prefiro fazer um renting e trocar quando me interesse.",
    image: "testimonials/manuel",
    car: "Seat Arona 1.0 TSI Style Edition Eco",
    rating: 5
  },
  {
    id: 4,
    name: "Gabriela",
    content:
      "Realizo muitos quilometros por ano e o renting resulta ser muito rentável. Estou muito feliz com o meu novo carro, e tenho a dizer que na Swipcar foram muito eficientes na gestão de todo o processo.",
    image: "testimonials/gisela",
    car: "Seat Arona 1.0 TSI Style Edition Eco",
    rating: 5
  },
  {
    id: 5,
    name: "Maria",
    content:
      "Sai-me mais em conta o renting porque evito a entrada inicial, valor que não me convinha pagar de uma vez.",
    image: "testimonials/maria",
    car: "Audi A1",
    rating: 5
  },
  {
    id: 6,
    name: "João Carlos",
    content:
      "Acho que com a prestação de ter tudo incluído poupo dinheiro. Estou cansado de comprar carros e vendê-los por um valor ridículo ao fim de uns anos.",
    image: "testimonials/juan-carlos",
    car: "Seat Arona 1.0 TSI Style Edition Eco",
    rating: 5
  },
  {
    id: 7,
    name: "João Carlos",
    content:
      "Recomendo a Swipcar, o processo foi simples e rápido. Estou muito contente com o meu Peugeot 3008. Só posso dizer que é incrível!",
    image: "testimonials/juan-carlos-3008",
    car: "Peugeot 3008 BlueHDI Active",
    rating: 5
  }
]
