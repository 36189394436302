import { useEffect } from "react"
import * as PropTypes from "prop-types"
import TopBar from "../../TopBar"
import { sendPageView as sendPageViewToGa } from "scripts/GoogleAnalytics"
import { init as initFB, sendPageView as sendPageViewToFb } from "scripts/FacebookPixel"
import { init as initHotjar } from "scripts/Hotjar"
import { init as initGTM } from "scripts/GoogleTagManager"
import { createCookie } from "helpers/marketingCookies"
import { MARKETING_FIELDS } from "helpers/constants"
import dynamic from "next/dynamic"
import LazyLoad from "react-lazyload"
import { useRouter } from "next/router"

const Footer = dynamic(() => import("../../Footer"), { ssr: false })

export default function DefaultLayout(props) {
  const { children, topBarProps = {}, footerProps = {} } = props

  const router = useRouter()

  useEffect(() => {
    if (typeof window !== "undefined") {
      MARKETING_FIELDS.forEach(field => {
        createCookie(field, router.query[field])
      })
      if (!window.HOTJAR_INITIALIZED) {
        initHotjar()
        window.HOTJAR_INITIALIZED = true
      }
      if (!window.GTM_INITIALIZED) {
        initGTM()
        window.GTM_INITIALIZED = true
      } else {
        sendPageViewToGa(router.pathname)
      }
      if (!window.FB_INITIALIZED) {
        initFB()
        window.FB_INITIALIZED = true
      } else {
        sendPageViewToFb(router.pathname)
      }
    }
  }, [])

  return (
    <>
      <TopBar {...topBarProps} />
      {children}
      <LazyLoad height={200}>
        <Footer {...footerProps} />
      </LazyLoad>
    </>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired
}
