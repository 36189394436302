import { captureException, withScope } from "@sentry/node"

const isProduction = process.env.NEXT_PUBLIC_ENVIRONMENT === "production"
const isServer = typeof window === "undefined"

export const GA_TRACKING_ID = "UA-106978377-4"

export function sendEvent(eventName, eventParameters) {
  if (isServer || !isProduction) return
  try {
    window.gtag("event", eventName, eventParameters)
  } catch (error) {
    sendToSentry(error)
  }
}

export function sendPageView(url) {
  if (isServer || !isProduction) return
  try {
    window.gtag("config", GA_TRACKING_ID, { page_path: url })
  } catch (error) {
    sendToSentry(error)
  }
}

export function sendLeadEvent(email, formName) {
  sendEvent("click", { event_category: formName, event_label: email ? "lead" : "lead call back" })
}

function sendToSentry(error) {
  withScope(scope => {
    scope.setFingerprint(["Gtag error"])
    captureException(error)
  })
}
